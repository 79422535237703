import { styled } from '@linaria/react';

export const StyledBookOrderListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-2xl) 0;

  body[data-mobile='true'] & {
    padding: var(--spacing-xl) 0;
  }
`;

export const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
`;

export const BookPreviewWrapper = styled.div`
  flex: 0 0 144px;

  body[data-mobile='true'] & {
    flex: 0 0 88px;
  }
`;

export const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidePanelDetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
`;
