import { useCallback } from 'react';
import { BaseAssetAlternativeType, ImageAssetAlternativeType } from '@remento/types/alternative';
import { BaseEntityStatus } from '@remento/types/base-entity';
import { BookOrderType } from '@remento/types/book-order';

import { useAlternativeType, useAssetAlternativesQuery, useAssetImageUrl } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useBookOrderQuery, usePollBookOrderUntilProcessed } from '@/services/api/book';
import { usePersonQuery } from '@/services/api/person';

import { BookOrderList } from '../components/BookOrderList';

export interface BookOrderListItemContainerProps {
  bookOrderId: string;
  onReorder: () => void;
  onViewDetails: () => void;
}

export function BookOrderListItemContainer({ bookOrderId, onReorder, onViewDetails }: BookOrderListItemContainerProps) {
  const user = useUser();

  // Queries
  const orderQuery = useBookOrderQuery(bookOrderId);
  const creatorPersonQuery = usePersonQuery(orderQuery.data?.creatorPersonId);
  const coverUrl = useAssetImageUrl(orderQuery.data?.coverAssetId ?? null, ImageAssetAlternativeType.SMALL);
  const order = orderQuery.data;
  const ebookAlternativesQuery = useAssetAlternativesQuery(
    order?.type == BookOrderType.EBOOK ? order.ebookAssetId : null,
  );
  const ebookAlternative = useAlternativeType(ebookAlternativesQuery.data, BaseAssetAlternativeType.ORIGINAL);

  // Poll the order until it's not processed
  usePollBookOrderUntilProcessed(bookOrderId, 5000);

  const handleDownload = useCallback(() => {
    if (ebookAlternative == null) {
      return;
    }
    const downloadUrl = new URL(ebookAlternative.url);
    downloadUrl.searchParams.set('download-name', `${order?.title.replaceAll(/\W+/gm, '-').toLowerCase()}.pdf`);
    window.open(downloadUrl.toString(), '_blank');
  }, [ebookAlternative, order]);

  if (order == null || order.status == BaseEntityStatus.DELETED) {
    return null;
  }

  return (
    <BookOrderList.Item
      title={order.title}
      subtitle={order.subtitle}
      color={order.color}
      coverUrl={coverUrl}
      status={order.status}
      creatorName={creatorPersonQuery.data?.name?.full ?? 'N/A'}
      purchaseDate={order.submittedOn}
      sentToName={order.type == BookOrderType.BOOK ? order.shipping.recipientName : null}
      quantity={order.type == BookOrderType.BOOK ? order.quantity : 0}
      creditsUsed={order.type == BookOrderType.BOOK ? order.credits : 0}
      ebook={order.type == BookOrderType.EBOOK}
      showViewDetails={user?.refIds.includes(order.purchaserUserId) ?? false}
      onViewDetails={onViewDetails}
      onReorder={onReorder}
      onDownload={handleDownload}
    />
  );
}
