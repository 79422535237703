import { styled } from '@linaria/react';

export const StyledBookOrderListRoot = styled.div`
  padding: 0 var(--spacing-xl);

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-sm);
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--border-hairline);
  }
`;
