import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookColor } from '@remento/types/book';
import { BookOrderStatus, EBookOrderStatus } from '@remento/types/book-order';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { BookCoverPreview } from '../BookCoverPreview/BookCoverPreview';
import { BookPreviewScaler } from '../BookPreviewScaler/BookPreviewScaler';

import {
  BookPreviewWrapper,
  ButtonsWrapper,
  ListItemContent,
  SidePanel,
  SidePanelDetailsList,
  StyledBookOrderListItem,
} from './BookOrderListItem.styles';

const BOOK_ORDER_STATUS_LABEL: Record<BookOrderStatus, string> = {
  [BookOrderStatus.AWAITING_PAYMENT]: 'Awaiting payment',
  [BookOrderStatus.PROCESSING]: 'Printing in progress',
  [BookOrderStatus.PRINTING]: 'Printing',
  [BookOrderStatus.SHIPPED]: 'In transit',
  [BookOrderStatus.CANCELED]: 'Canceled',
};

const EBOOK_ORDER_STATUS_LABEL: Record<EBookOrderStatus, string> = {
  [EBookOrderStatus.AWAITING_PAYMENT]: 'Awaiting payment',
  [EBookOrderStatus.PROCESSING]: 'Generating E-book',
  [EBookOrderStatus.CANCELED]: 'Canceled',
  [EBookOrderStatus.EMAILED]: 'Emailed',
};

export interface BookOrderListItemProps {
  title: string;
  subtitle: string | null;
  coverUrl: string | null;
  color: BookColor;
  status: BookOrderStatus | EBookOrderStatus;
  purchaseDate: number;
  creatorName: string;
  sentToName: string | null;
  quantity: number;
  creditsUsed: number;
  ebook: boolean;
  showViewDetails: boolean;
  onViewDetails: () => void;
  onReorder: () => void;
  onDownload: () => void;
}

export function BookOrderListItem({
  title,
  subtitle,
  coverUrl,
  color,
  status,
  purchaseDate,
  creatorName,
  sentToName,
  quantity,
  creditsUsed,
  ebook,
  showViewDetails,
  onViewDetails,
  onReorder,
  onDownload,
}: BookOrderListItemProps) {
  const isMobile = useIsMobileViewport();

  const Buttons = (
    <ButtonsWrapper>
      {ebook && (status == EBookOrderStatus.PROCESSING || status == EBookOrderStatus.EMAILED) && (
        <RMButton
          background="primary"
          autoLoading
          fullWidth={isMobile}
          disabled={status != EBookOrderStatus.EMAILED}
          onClick={onDownload}
        >
          Download
        </RMButton>
      )}
      {ebook == false && (
        <RMButton background="primary" autoLoading fullWidth={isMobile} onClick={onReorder}>
          Reorder
        </RMButton>
      )}
      {showViewDetails && ebook == false && (
        <RMButton background="neutral" autoLoading fullWidth={isMobile} onClick={onViewDetails}>
          View details
        </RMButton>
      )}
    </ButtonsWrapper>
  );

  return (
    <StyledBookOrderListItem>
      <ListItemContent>
        <BookPreviewWrapper>
          <BookPreviewScaler height={isMobile ? 110 : 180} center>
            <BookCoverPreview
              title={title}
              subtitle={subtitle}
              color={color}
              photoUrl={coverUrl}
              padding={false}
              hideSideCover
              ebookBanner={ebook}
            />
          </BookPreviewScaler>
        </BookPreviewWrapper>

        <SidePanel>
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            {title}
          </RMText>
          <RMSpacer direction="column" spacing="xs" />

          <SidePanelDetailsList>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              Created by {creatorName}
              {sentToName != null ? ` · Sent to ${sentToName}` : ''}
            </RMText>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              {dayjs(purchaseDate).format('MMMM DD, YYYY')}
            </RMText>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
              Status:{' '}
              {ebook && status == EBookOrderStatus.PROCESSING && (
                <>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                  &nbsp;
                </>
              )}
              {ebook
                ? EBOOK_ORDER_STATUS_LABEL[status as EBookOrderStatus]
                : BOOK_ORDER_STATUS_LABEL[status as BookOrderStatus]}
            </RMText>

            {ebook == false && (
              <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary">
                Copies: {quantity}
                {creditsUsed > 0 ? ` (${creditsUsed} credit${creditsUsed > 1 ? 's' : ''} redeemed)` : ''}
              </RMText>
            )}
          </SidePanelDetailsList>

          {isMobile == false && (
            <>
              <RMSpacer direction="column" spacing="md" />
              {Buttons}
            </>
          )}
        </SidePanel>
      </ListItemContent>
      {isMobile && Buttons}
    </StyledBookOrderListItem>
  );
}
