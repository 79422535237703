import { useCallback } from 'react';
import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { BaseEntityStatus } from '@remento/types/base-entity';
import { BookOrderType } from '@remento/types/book-order';
import { getShippingUrl } from '@remento/utils/shipping/get-shipping-url';

import { useServices } from '@/Services';
import { useAssetImageUrl } from '@/services/api/asset';
import { useBookOrderQuery } from '@/services/api/book';
import { usePersonQuery } from '@/services/api/person';

import { BookOrderDetails } from '../components/BookOrderDetails/BookOrderDetails';

export interface BookOrderDetailsContainerProps {
  bookOrderId: string;
  onReorder: () => void;
}

export function BookOrderDetailsContainer({ bookOrderId, onReorder }: BookOrderDetailsContainerProps) {
  // Services
  const { intercomService } = useServices();

  // Queries
  const orderQuery = useBookOrderQuery(bookOrderId);
  const creatorPersonQuery = usePersonQuery(orderQuery.data?.creatorPersonId);
  const purchaserPersonQuery = usePersonQuery(orderQuery.data?.purchaserPersonId);
  const coverUrl = useAssetImageUrl(orderQuery.data?.coverAssetId ?? null, ImageAssetAlternativeType.SMALL);

  const handleOpenTracking = useCallback(() => {
    if (orderQuery.data == null || orderQuery.data.type != BookOrderType.BOOK) {
      return;
    }

    const tracker = orderQuery.data.tracker;
    if (tracker != null) {
      window.open(getShippingUrl(tracker.id, tracker.provider), '_blank');
    }
  }, [orderQuery.data]);

  const handleContactSupport = useCallback(() => {
    intercomService?.setChatDisplayed(true);
  }, [intercomService]);

  if (orderQuery.data == null || orderQuery.data.status === BaseEntityStatus.DELETED) {
    return null;
  }
  if (orderQuery.data.type !== BookOrderType.BOOK) {
    throw new Error('Unsupported book type');
  }

  return (
    <BookOrderDetails
      orderId={orderQuery.data.id}
      title={orderQuery.data.title}
      subtitle={orderQuery.data.subtitle}
      color={orderQuery.data.color}
      coverUrl={coverUrl}
      purchaserName={purchaserPersonQuery.data?.name?.full ?? 'N/A'}
      creatorName={creatorPersonQuery.data?.name?.full ?? 'N/A'}
      purchaseDate={orderQuery.data.submittedOn}
      status={orderQuery.data.status}
      quantity={orderQuery.data.quantity}
      creditsUsed={orderQuery.data.credits}
      shippingAddress={orderQuery.data.shipping}
      trackingNumber={orderQuery.data.tracker?.id ?? null}
      onReorder={onReorder}
      onOpenTracking={handleOpenTracking}
      onContactSupport={handleContactSupport}
    />
  );
}
