import { BookColor, BookColorMap } from '@remento/types/book';

import { getBookTextColor } from '../../utils/book-color.utils';

import { BookCoverDecorator } from './BookCoverDecorator';
import {
  Cover,
  CoverContent,
  CoverPhoto,
  CoverShadow,
  CoverSubtitle,
  CoverTitle,
  CoverTitleDecorator,
  EbookBanner,
  Side,
  SideDelimiter,
  SideText,
  SideTextContainer,
  SideTextSeparator,
  StyledBookCoverPreview,
} from './BookCoverPreview.styles';
import { BookCoverSideLogo } from './BookCoverSideLogo';

function getShadowColor(color: BookColor | null): string | null {
  if (color === null) {
    return null;
  }

  // This is hacky, but at least it works
  const hslaColor = BookColorMap[color];
  if (hslaColor.startsWith('hsla(') === false) {
    throw new Error('Book color is not defined in the HSLA format');
  }

  return hslaColor.replace('1)', '0.49)');
}

function getColor(color: BookColor | null): string | null {
  return color === null ? null : BookColorMap[color];
}

export interface BookCoverPreviewProps {
  title: string | null;
  subtitle: string | null;
  photoUrl?: string | null;
  color: BookColor | null;
  padding?: boolean;
  hideSideCover?: boolean;
  ebookBanner?: boolean;
}

export function BookCoverPreview({
  title,
  subtitle,
  photoUrl,
  color,
  padding = true,
  hideSideCover = false,
  ebookBanner = false,
}: BookCoverPreviewProps) {
  return (
    <StyledBookCoverPreview
      style={{
        '--color': getColor(color),
        '--shadow-color': getShadowColor(color),
        '--text-color': getBookTextColor(color),
      }}
      data-padding={padding}
    >
      <Cover>
        <CoverShadow />
        <CoverContent>
          {ebookBanner && <EbookBanner>E-book</EbookBanner>}
          <CoverTitle data-has-photo={photoUrl != null}>
            {photoUrl != null && <CoverTitleDecorator data-position="top" />}
            {title}
            {photoUrl != null && <CoverTitleDecorator data-position="bottom" />}
          </CoverTitle>
          {photoUrl != null ? <CoverPhoto src={photoUrl} /> : <BookCoverDecorator bookColor={color} />}
          <CoverSubtitle>{subtitle}</CoverSubtitle>
        </CoverContent>
      </Cover>
      {hideSideCover == false && (
        <Side>
          <SideDelimiter>
            <SideTextContainer>
              <SideText>{title}</SideText>
              {title && subtitle && <SideTextSeparator />}
              <SideText>{subtitle}</SideText>
            </SideTextContainer>
            <BookCoverSideLogo bookColor={color} />
          </SideDelimiter>
        </Side>
      )}
    </StyledBookCoverPreview>
  );
}
