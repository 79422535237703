import { PropsWithChildren } from 'react';

import { BOOK_PREVIEW_HEIGHT } from '../BookCoverPreview/BookCoverPreview.styles';

import { ScaledBookPreviewWrapper, Scaling } from './BookPreviewScaler.styles';

type BookPreviewScalerProps = PropsWithChildren<{
  height: number;
  center?: boolean;
}>;

export function BookPreviewScaler({ center = false, height = BOOK_PREVIEW_HEIGHT, children }: BookPreviewScalerProps) {
  return (
    <ScaledBookPreviewWrapper
      style={{
        '--height': `${height}px`,
      }}
    >
      <Scaling
        data-center={center}
        style={{
          '--height': height,
        }}
      >
        {children}
      </Scaling>
    </ScaledBookPreviewWrapper>
  );
}
