import { styled } from '@linaria/react';

import { BOOK_PREVIEW_HEIGHT } from '../BookCoverPreview/BookCoverPreview.styles';

export const ScaledBookPreviewWrapper = styled.div`
  height: var(--height);
  width: 100%;
  position: relative;
`;

export const Scaling = styled.div`
  position: absolute;
  left: 0%;
  transform-origin: 0 0;
  transform: scale(calc(var(--height) / ${BOOK_PREVIEW_HEIGHT})) translate(0%);

  &[data-center='true'] {
    left: 50%;
    transform: scale(calc(var(--height) / ${BOOK_PREVIEW_HEIGHT})) translate(-50%);
  }
`;
