import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const StyledBookOrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  padding: 0 var(--spacing-xl);

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
    padding: 0 var(--spacing-sm);
  }
`;

export const TopPanel = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2xl);

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
  }
`;

export const TopPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
  justify-content: center;
`;

export const BookPreviewWrapper = styled.div`
  flex: 0 0 240px;

  body[data-mobile='true'] & {
    flex: 0 0 88px;
  }
`;

export const Separator = styled.div`
  flex: 0 0 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const ShippingAddressText = styled(RMText)`
  white-space: pre;
`;

export const ClickableText = styled(RMText)`
  cursor: pointer;
`;

export const OrderNumberText = styled(RMText)`
  line-break: anywhere;
`;
